import React from 'react'
import { Grid } from '@mui/material'
import anos from './gato.png'
import uni from './ray.png'
import fine from './elgat.mp4'
import dex from './ds.png'
import log from './dexos.png'
import eagle from './eagle.gif'
import Marquee from "react-fast-marquee";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import noot1 from './nan.jpeg'

function Desktop() {
  return (
    <div className='container'>
      <br/>
          <Grid container>
          <Grid item md={2} xs={12} lg={2} className='centerall'>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_textdglogo">
$GATO
          
          
          </h1>
   
      </Grid>
          <Grid item md={8} xs={12} lg={8} className='centerall'>
       
          <a href="https://t.me/ElGatoCat" 
          style={{textDecoration:"none"}}
          target='_blank'
          
          ><FaTelegramPlane className='socials' />
     </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <a href=""
           target='_blank'
          style={{textDecoration:"none"}}
          
          > <img src={log} style={{width:"60px"}} /> </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             
             <a href="https://twitter.com/ElGatoCatSol" 
               target='_blank'
          style={{textDecoration:"none"}}
          
          >  <FaXTwitter className='socials' />  </a>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

         </Grid>
         <Grid item md={2} xs={12} lg={2} className='centerall'>
         <a href=""
className="button"
          style={{textDecoration:"none"}}
          target="_ blank"
          > Buy Now </a>
   
      </Grid>
          </Grid>
          <br/>  

          <Grid container>
          <Grid item md={6} xs={12} lg={6} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
         <Grid item md={6} xs={12} lg={6} className='centerall'>
         <br/> <br/>  <br/>  <br/>
         <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_textdg">
Elgato Cat on Solana .
          
          
          </h1>
          <p className='anon_p'>Where memes meet the blockchain, and every transaction is purr-fectly hilarious!" 🐱💰 </p>
         </Grid>
          </Grid>

          <br/>  <br/>


          <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
         
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <p className='anon_p'> </p>
         </Grid>

       
          </Grid>

         
          <Grid item md={12} xs={12} lg={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20"><div class="flex justify-center">
  <div class="card"><div class="boarding">
    </div><div class="card-content"><h2 class="matrix-text card-title py-2 tokcolo">
    <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_textf">Contract Address</h1>
      </h2></div>
      
      <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           
       <p style={{color:"#fff"}}>Coming Soon ..... </p>
   

        
         </Grid>
         <Grid item md={12} xs={12} lg={12} sm={12} className='centerallvideo'>
        <div className='css-hywirf'>
          <video width="100%" height='100%' controls muted >
            <source src={fine} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
          </Grid> <br/>
      </div></div>
      
      
      </div>
</Grid>

          
     



          






<br/>  <br/>
<br/>  <br/>
    </div>
  )
}

export default Desktop